import { Badge } from './ui/badge';
import { Input } from './ui/input';

import type { BatchFormData } from './forms/batch-form';
import type { ControllerRenderProps } from 'react-hook-form';

export type TagsField = Pick<BatchFormData, 'tags'>;

interface TagsProps {
  tags?: string[];
  onRemove: (tagToRemove: string) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  field: ControllerRenderProps<TagsField>;
  tagInput: string;
}

export function Tags({ tags, onRemove, onChange, field, tagInput }: TagsProps) {
  return (
    <div className="flex flex-wrap items-center gap-2 p-2 border rounded-md focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
      {Array.isArray(field.value) &&
        tags?.map((tag, index) => (
          <Badge className="text-sm" key={index} variant="secondary">
            {tag}
            <button
              className="ml-1 text-xs font-bold"
              onClick={() => {
                onRemove(tag);
              }}
              type="button"
            >
              ×
            </button>
          </Badge>
        ))}
      <Input
        {...field}
        className="flex-1 border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
        onChange={onChange}
        placeholder="Type a tag and press comma to add"
        value={tagInput}
      />
    </div>
  );
}
